(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/get-cms-data/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/lb-utils/get-cms-data/assets/javascripts/utils.js');

'use strict';

let logger;
let jupiter;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  logger = trinidad.logger('lb-utils:get-cms-data');
  jupiter = trinidad.jupiter;
} else {
  logger = svs.core.log.getLogger('lb-utils:get-cms-data');
  jupiter = svs.core.jupiter;
}

const getCmsDataDocumentAsync = async id => {
  try {
    const options = {
      method: 'GET',
      path: new svs.content.shared.ContentUrlBuilder('id').addParams({
        ids: [id],
        count: 100
      }).build()
    };
    logger.debug('Call to getCmsDataDocumentAsync with options: ', options);
    const {
      response
    } = await jupiter.callAsync(options);
    logger.debug('Call to getCmsDataDocumentAsync response: ', response);
    return response;
  } catch (error) {
    logger.warn('Error getting CMS Data Async', error);
    return {
      error
    };
  }
};

const getCmsDataFolderAsync = async parentId => {
  try {
    const options = {
      method: 'GET',
      path: new svs.content.shared.ContentUrlBuilder('basicfilter').addParams({
        parentId,
        count: 100
      }).build()
    };
    logger.debug('Call to getCmsDataFolderAsync with options: ', options);
    const {
      response
    } = await jupiter.callAsync(options);
    logger.debug('Call to getCmsDataFolderAsync response: ', response);
    return response;
  } catch (error) {
    logger.warn('Error getting CMS Data Async', error);
    return {
      error
    };
  }
};
const toExport = {
  getCmsDataDocumentAsync,
  getCmsDataFolderAsync
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.lbUils.getCmsData', toExport);
}

 })(window);